import { useState } from "react"
import { OrganizeGraphData } from "../../components/graph/graphdata"
import Dashboard from '../dashboard/Dashboard'

function Início() {
    const [error, setError] = useState(false)
    const { links, nodes, hidden } = OrganizeGraphData({ setError, client: 'dash' })

    sessionStorage.removeItem('lastTime');
    sessionStorage.removeItem('lastTimeServer');
    sessionStorage.removeItem('lastTimeApp');

    return (
        <Dashboard error={error} nodes={nodes} links={links} setError={setError} hidden={hidden} />
    )
}

export default Início