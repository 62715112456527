import { UpdatePAssword } from "../../store"
import { useRecoilState } from "recoil"

export function LogUtils({ formData, setMsg, setSenhas, navigate, setUpdate, FetchData }) {
    const [updatePassword, setUpdatePassword] = useRecoilState(UpdatePAssword)

    function getParams(recover, update) {
        if (update) {
            return { password: formData?.password, 'user_id': localStorage.getItem('user_id') }
        } else if (recover) {
            return { email: formData?.email }
        } else {
            return { email: formData?.email, password: formData?.password }
        }
    }


    function login(recover, update) {
        (async () => {
            if (update == true && formData?.password?.length < 8) {
                setMsg('As senhas devem ter no minimo 8 caracteres')
                setSenhas(true)
            } else {
                let response = await FetchData(getParams(recover, update), recover ? 'passwordRequest' : update ? 'updatePassword' : 'login', 'POST', false, null)

                if (response && !recover && !updatePassword) {
                    localStorage.setItem('token', response?.token);
                    localStorage.setItem('user_name', response?.user?.name + ' ' + response?.user?.apelido);
                    localStorage.setItem('user_id', response?.user?.id);
                    localStorage.setItem('acesso', response?.user?.acesso);
                    localStorage.setItem('novo', response?.user?.novo);
                    if (response?.user?.novo == 0) {
                        navigate('/dashboard')
                    } else if (response?.user?.novo == 1) {
                        setUpdate(true)
                    } else if (response?.status == 1) {
                        setUpdate(false)
                    }
                } else if (updatePassword) {
                    setUpdatePassword(false)
                    navigate(-1)
                }
            }
        })()
    }

    return { login }
}