export function hideNodes() {

    function returnObj(type, visible, cluster_id) {
        let hide;
        hide = {
            "tipo": type,
            "user_id": localStorage.getItem('user_id'),
            "cluster_id": cluster_id ? cluster_id : null,
            "hide": !visible ? true : false,
            "all": cluster_id ? false : true,
            "operation": "update"
        }
        return hide
    }

    return { returnObj }
}