import React, { useState, useEffect, useContext } from "react";
import styles from './ListUser.module.css'
import Tabela from '../../components/table/Tabela'
import { PersonAdd } from "@mui/icons-material";
import { Fab } from "@mui/material";
import { Link } from "react-router-dom";
import { GeneralFetch } from "../../api/generalFetch/generalFetch";
import Filter from "../../components/filter/Filter";
import { useRecoilState } from "recoil";
import { RowsPerPage } from "../../store";
import useDebounce from "../../utils/search/searchDebounce";

function ListUser() {
    const [page, setPage] = useState(1);
    const { FetchData, data, load } = GeneralFetch()
    const [perPage,] = useRecoilState(RowsPerPage)
    const [infos, setInfos] = useState({});
    const debouncedValue = useDebounce()

    function updateInfosItem(key, value) {
        setInfos({ ...infos, [key]: value });
    }

    useEffect(() => {
        (async () => {
            await FetchData(infos, `getUsers?page=${page}&per_page=${perPage}&Nome=${debouncedValue}`, 'POST', true, 'Users')
        })()
    }, [infos, page, perPage, debouncedValue])

    return (
        <div className={styles.container}>
            <div>
                <Filter object='user' infos={infos} setInfos={setInfos} updateInfosItem={updateInfosItem} />
                {data && <Tabela page={page} setPage={setPage} data={data} obj={'users'} load={load} />}
            </div>
            <Link to='/dashboard/utilizadores/registar'>
                <Fab color="primary" aria-label="add"
                    style={{ position: 'fixed', bottom: '30px', right: 16, borderRadius: '100%', backgroundColor: '#00559A' }}
                >
                    <PersonAdd style={{ color: '#fff' }} />
                </Fab>
            </Link>
        </div>
    )
}

export default ListUser;
