import React, { useState } from "react";
import { Box, IconButton } from "@mui/material";
import Selects from "./select/Select";
import styles from './Filter.module.css'
import DataPicker from "./period/DataPicker";
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import ViewListIcon from '@material-ui/icons/ViewList';
import InsertChartIcon from '@mui/icons-material/InsertChart';

const ops = [
    { "key": "server", "value": "Servidor" },
    { "key": "container", "value": "Container" },
    { "key": "app", "value": "Aplicação" }
]

const options = [
    { "key": "0", "value": "Mais Frequente" },
    { "key": "1", "value": "Up" },
    { "key": "4", "value": "Desligado" },
    { "key": "2", "value": "Saturado" },
    { "key": "3", "value": "Down" }
]

const options2 = [
    { "key": "2", "value": "Todos" },
    { "key": "1", "value": "Activos" },
    { "key": "0", "value": "Desativados" },
]

const datas = [
    { 'key': 'dataI', 'label': 'Inicio' },
    { 'key': 'dataF', 'label': 'Fim' }
]

function Filter({ object, infos, setInfos, setToogle, toogle, }) {
    const [show, setShow] = useState(false)
    const [viewType, setViewType] = useState({ table: '#03a9f4' })
    const style = { borderBottom: 'solid #0A369D 3px', width: '23px', margin: '-5px 0 0 4.5px' }

    function viewUpdate(type) {
        setViewType({ [type]: '#03a9f4' })
        setToogle(!toogle)
    }

    function RemoveFilters() {
        setInfos(infos => ({ ...infos, Estado: 0, dataI: null, dataF: null }))
    }

    return (
        <div style={{ display: 'grid', gridTemplateColumns: '97% 2%' }} >
            <div className={styles.container}>
                {
                    !object &&
                    <div style={{ display: 'grid', gridTemplateColumns: '20% 70%', marginTop: '32px' }} >
                        <label style={{ fontSize: '19px', margin: '-4px 10px 0 0' }} >Cluster:</label>
                        <Selects show={false} label='Tipo' values={ops} placeholder='Selecione um tipo de objecto' infos={infos} setInfos={setInfos} />
                    </div>
                }
                {
                    !!((show & !object) || object) && < div style={{ display: 'grid', gridTemplateColumns: '20% 70%', marginTop: '32px' }} >
                        <label style={{ fontSize: '19px', margin: '-4px 10px 0 0' }} >Estado:</label>
                        <Selects show={false} label='Estado' values={object ? options2 : options} placeholder='Selecione um estado' infos={infos} setInfos={setInfos} />
                    </div>
                }
                {
                    !!(!object & show) &&
                    <Box>
                        <div className={styles.datapicker}>
                            {
                                datas.map((datas) => {
                                    return <DataPicker keyy={datas.key} label={datas.label} infos={infos} setInfos={setInfos} />
                                })
                            }
                        </div>
                    </Box>
                }
                {
                    !object &&
                    <div style={{ position: 'absolute', right: '0', marginRight: '30px' }}>
                        <div style={{ display: "flex" }} >
                            <IconButton style={{ width: "28px", height: "28px", marginTop: '11px', marginRight: "5px" }} onClick={() => {
                                if (show) {
                                    RemoveFilters()
                                }
                                setShow(!show)
                            }}>
                                {
                                    show ?
                                        <FilterListOffIcon style={{ fontSize: '28px' }} /> :
                                        <FilterListIcon style={{ fontSize: '28px' }} />
                                }
                            </IconButton>
                            <div style={{ marginTop: '10px' }} >
                                <ViewListIcon style={{ cursor: 'pointer', color: viewType.table ? viewType.table : '#757575', fontSize: '34px' }} onClick={() => { viewUpdate("table") }} />
                                {viewType.table && <hr style={{ ...style, marginLeft: "6px" }} />}
                            </div>
                            <div style={{ margin: "12px 10px 0 3px" }}>
                                <InsertChartIcon style={{ cursor: 'pointer', color: viewType.chart ? viewType.chart : '#757575', fontSize: '32px' }} onClick={() => { viewUpdate("chart") }} />
                                {viewType.chart && <hr style={style} />}
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div >
    )
}

export default Filter