import React from "react";
import styles from './Legenda.module.css';
import { Square } from "@mui/icons-material";
import { Close } from "@material-ui/icons";
import { Tooltip } from "@mui/material";
import Button from '../buttons/Button'

function Legenda({ estados }) {
    const [show, setShow] = React.useState(true)

    return (
        <React.Fragment>
            <div className={styles.container}>
                {
                    show ?
                        <React.Fragment>
                            <div className={styles.tooltip} onClick={() => setShow(!show)}>
                                <Tooltip title='Fechar Legenda'>
                                    <Close style={{ fontSize: '1rem', cursor: 'pointer' }} />
                                </Tooltip>
                            </div>
                            <ul className={styles.ul} >
                                {
                                    estados?.map((estados, index) => {
                                        return (
                                            <div key={index} className={styles.states}>
                                                <li><Square
                                                    style={{ color: `${estados.color}`, width: '20%' }}
                                                    className={styles.icon}
                                                />
                                                </li>
                                                <li className={styles.legenda}>{estados.state}</li>
                                            </ div>
                                        )
                                    })
                                }
                            </ul>
                        </React.Fragment>
                        :
                        <div className={styles.legendaBtn}>
                            <Button
                                variant='text'
                                label='Legenda'
                                color='primary'
                                className={styles.legendaBtn}
                                onClick={() => setShow(!show)}
                            />
                        </div>
                }
            </div>
        </React.Fragment>
    )

}

export default Legenda