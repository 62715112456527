import React, { useEffect } from "react";
import { Grafo } from '../../components/graph/graph'
import CircularProgress from '@material-ui/core/CircularProgress';
import { useState } from "react";
import { legenda } from "../../utils/variables/legenda";
import Legenda from "../../components/legenda/Legenda";
import styles from "./Dashboard.module.css"
import Settings from "../../components/settings/Setting";

function Dashboard({ links, nodes, error, hidden }) {
  const { dashboard } = legenda()
  const [updateForm, setUpdateForm] = useState()
  const [cluster, setClusters] = useState([])

  useEffect(() => {
    let data = []
    hidden?.map((element) => {
      if (element.clusterName == 'apps') {
        data[0] = 'apps'
      } else if (element.clusterName == 'servers') {
        data[1] = 'servers'
      } else if (element.clusterName == 'containers') {
        data[2] = 'containers'
      }
    })
    setClusters(data)
  }, [hidden])

  return (
    ((links.length > 0 || nodes.length > 0) || (nodes == 1) || error) ?
      <div>
        {
          error ?
            <div className={styles.container}>
              <h1 style={{ color: 'red' }} >Ocorreu um erro na busca dos dados</h1>
            </div>
            :
            nodes?.length > 0 ?
              <div>
                <Grafo links={links} nodes={nodes} client='dash' updateForm={updateForm} setUpdateForm={setUpdateForm} />
                <Legenda estados={dashboard} />
                <Settings hidden={cluster} updateForm={updateForm} setUpdateForm={setUpdateForm}/>
              </div>
              :
              <div className={styles.container}>
                <h1>Não há  dados para mostrar</h1>
              </div>
        }
      </div>
      :
      <div className={styles.container}>
        <CircularProgress color="primary" />
      </div>
  );
}

export default Dashboard

