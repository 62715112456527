import { atom } from 'recoil'

export const RowsPerPage = atom({
    key: "RowsPerPage",
    default: 12
})

export const Alerta = atom({
    key: "Alerta",
    default: {}
})

export const AppLocation = atom({
    key: "AppLocation",
    default: 'Container'
})

export const OpenModal = atom({
    key: "OpenModal",
    default: { open: false, component: 'password' }
})

export const SearchData = atom({
    key: "SearchData",
    default: ""
})

export const UpdateItems = atom({
    key: "UpdateItems",
    default: { update: false }
})

export const UpdatePAssword = atom({
    key: "UpdateItems",
    default: false
})

export const UserUpdate = atom({
    key: "UserUpdate",
    default: {}
})