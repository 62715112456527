export function ServerToUpdate({ setOrganizedData }) {
    const remove = []
    const add = []
    let resp = []

    function organize(data, servers) {
        if (data && servers) {
            for (let index = 0; index < data?.mine?.length; index++) {
                for (let contr = 0; contr < servers?.servers.length; contr++) {
                    if (data.mine[index]['id'] === servers.servers[contr]['id']) {
                        break;
                    } else if (data.mine[index]['id'] !== servers.servers[contr]['id'] && contr === servers?.servers.length - 1) {
                        remove.push(data.mine[index]['id'])
                    }
                }
            }

            for (let contr = 0; contr < servers?.servers.length; contr++) {
                if (data?.mine?.length <= 0) {
                    for (let contr = 0; contr < servers?.servers.length; contr++) {
                        add.push(servers.servers[contr]['id'])
                    }
                } else {
                    for (let index = 0; index < data?.mine?.length; index++) {
                        if (data.mine[index]['id'] === servers.servers[contr]['id']) {
                            break;
                        } else if (data.mine[index]['id'] !== servers.servers[contr]['id'] && index === data?.mine?.length - 1) {
                            add.push(servers.servers[contr]['id'])
                        }
                    }
                }
            }
            resp.push(add)
            resp.push(remove)
            setOrganizedData(resp)
        }
    }
    return { organize }
}