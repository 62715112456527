import './style.css'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import Logo from '../../assets/logoBlack.svg'
import { useEffect, useState } from "react";
import { GeneralFetch } from "../../api/generalFetch/generalFetch";
import { useNavigate } from "react-router-dom";
import React from "react";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import CircularProgress from '@mui/material/CircularProgress';
import { LogUtils } from '../../utils/login/loginUtils';
import { useRecoilState } from 'recoil';
import { UpdatePAssword } from '../../store';

export default function Login() {
  const [formData, setFormData] = useState({})
  const navigate = useNavigate();
  const { FetchData, load, data } = GeneralFetch()
  const [recover, setRecover] = useState(false)
  const [update, setUpdate] = useState(false)
  const [senhas, setSenhas] = useState(false)
  const [msg, setMsg] = useState('As senhas nao sao iguais')
  const { login } = LogUtils({ formData, navigate, setMsg, setSenhas, setUpdate, FetchData })
  const [updatePassword, setUpdatePassword] = useRecoilState(UpdatePAssword)

  useEffect(() => {
    setSenhas(false)
  }, [data])

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  useEffect(() => {
    if ((updatePassword || update) && formData.password != null && formData.cPassword != null && formData.password != formData.cPassword) {
      setSenhas(true)
      setMsg('As senhas nao sao iguais')
    } else
      setSenhas(false)
  }, [formData])

  return (
    <div className='logContainer' >
      <div className="screen-1">
        <div style={{ height: '100px', display: 'flex', justifyContent: 'center', margin: '-20px 0 30px 0' }} >
          <img src={Logo} style={{ width: '145px' }} />
        </div>

        {senhas && <h1>{msg}</h1>}

        <div className="email" >
          <label htmlFor="email">
            {(update || updatePassword) ? 'Nova senha' : "Email"}
          </label>
          <div className="sec-2">
            {(update || updatePassword) ? <LockOutlinedIcon style={{ position: 'absolute' }} /> : <EmailOutlinedIcon style={{ position: 'absolute' }} />}

            <input
              type={(update || updatePassword) ? 'password' : "email"}
              name={(update || updatePassword) ? 'password' : "email"}
              value={(update || updatePassword) ? formData?.password : formData?.email}
              placeholder={(update || updatePassword) ? '...........' : 'exemplo@eg.bla'}
              onChange={onChange}
            />
          </div>
        </div>

        {!recover &&
          <div className="password" >
            <label htmlFor="password">
              {!(update || updatePassword) ? 'Senha' : "Confirme a senha"}
            </label>
            <div className="sec-2">

              <LockOutlinedIcon style={{ position: 'absolute' }} />
              <input
                className="pas"
                type="password"
                name={(update || updatePassword) ? 'cPassword' : "password"}
                value={(update || updatePassword) ? formData?.cPassword : formData?.Password}
                placeholder='...........'
                onChange={onChange}
              />
            </div>
          </div>
        }

        <button className="login" onClick={() => { login(recover, update || updatePassword) }} disabled={load} >
          {
            load ? <CircularProgress size={15} style={{ color: 'white' }} /> :
              recover ? 'Recuperar senha' : (update || updatePassword) ? 'Actualizar senha' : 'Login'
          }
        </button>
        <div className="footer">
          <span onClick={() => {
            if (!update && !updatePassword)
              setRecover(!recover)
            else if (updatePassword) {
              setUpdatePassword(false)
              navigate(-1)
            }
            else
              setUpdate(false)
          }} >
            {recover || (update || updatePassword) ? <KeyboardBackspaceIcon /> : 'Esqueceu senha?'}
          </span>
        </div>
      </div>
    </div>
  );
}