import { lineChartData } from './ChartData'
import ReactEcharts from "echarts-for-react"

function LineChart({ data }) {
    const { options } = lineChartData(data)

    return (
        <ReactEcharts
            option={options}
            style={{ width: "680px", height: "220px" }}
        ></ReactEcharts>
    )
}

export default LineChart
