import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { SearchData } from "../../store";

export default function useDebounce() {
    const [search,] = useRecoilState(SearchData)
    const [debouncedValue, setDebouncedValue] = useState(search)

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setDebouncedValue(search)
        }, 300)

        return () => clearTimeout(timeoutId)

    }, [search])

    return debouncedValue
}