import * as React from 'react';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

export default function DataPicker({ keyy, label, infos, setInfos }) {

  const handleChange = (newValue) => {
    setInfos({ ...infos, [keyy]: newValue });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MobileDatePicker
        label={label}
        value={infos?.[keyy] || null}
        onChange={handleChange}
        renderInput={(params) => <TextField size='small' sx={{
          "& .MuiInputBase-input": {
            height: "2px"
          }
        }} {...params} />}
      />
    </LocalizationProvider>
  );
}
