import React from 'react';
import Switch from '@material-ui/core/Switch';
import { useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import EditIcon from '@material-ui/icons/Edit';
import { useNavigate } from 'react-router-dom';
import { GeneralFetch } from '../../api/generalFetch/generalFetch';
import { IconButton } from '@mui/material';
import { Popconfirm } from 'antd';
import { UpdateItems } from '../../store';
import { useRecoilState } from 'recoil';

export default function SwitchOrEdit({ id, status, state, setState, obj, user = true, objID, rowID, data, setData, row }) {
    const { FetchData, load } = GeneralFetch()
    const [, setDados] = useRecoilState(UpdateItems)
    const navigate = useNavigate();
    let endpoint = 0

    function text() {
        if (state?.[id] == 1)
            return 'Tem certeza que pretende desligar?'
        else
            return 'Tem certeza que pretende ligar?'
    }

    function description() {
        if (state?.[id] == 1)
            return 'Vai parar de receber notificacoes sobre esse recurso.'
        else
            return 'Vai receber notificacoes sobre esse recurso.'
    }


    useEffect(() => {
        if (state) {
            let ObjectStatus = {};
            ObjectStatus[id] = parseInt(status)
            setState(state => ({
                ...state,
                ...ObjectStatus
            }));
        }
    }, [id])

    function Navigate() {
        setDados(dados => ({ ...dados, update: true, data: row, object: obj }));
        sessionStorage.setItem("menuItem", "Registo")
        navigate('/dashboard/registo')
    }

    const handleChange = () => {
        let value = state[id] == 1 ? 0 : 1

        switch (obj) {
            case 'server':
                endpoint = 'activate-deactivate-server'
                break;
            case 'container':
                endpoint = 'activate-deactivate-container'
                break;
            default:
                endpoint = 'activate-deactivate-app'
                break;
        }

        (async () => {
            const request = {
                ids: [id],
                estado: value
            }
            let response = await FetchData(request, endpoint, 'POST', false, null)
            if (response.success) {
                setState({ ...state, [id]: value });
            }
        })()
    };

    return (
        <div style={{ justifyContent: 'space-evenly', display: 'flex', alignItems: 'center' }} >
            {user ?
                !load ?
                    <>
                        <span style={{ fontSize: '14px' }} >Off</span>
                        <Popconfirm
                            placement="topRight"
                            title={text}
                            description={description}
                            onConfirm={handleChange}
                            okText="Sim"
                            cancelText="Nao"
                        >
                            <Switch
                                checked={state?.[id] == 1 ? 1 : 0}
                                name={id}
                                color="primary"
                                size='small'
                            />
                        </Popconfirm>
                        <span style={{ fontSize: '14px' }} >On</span>
                    </>
                    :
                    <CircularProgress size={20} />
                :
                <>
                    {load ?
                        <CircularProgress size={20} />
                        :
                        <>
                            <IconButton onClick={() => { Navigate() }} sx={{ color: 'black' }} style={{ padding: '0' }} >
                                <EditIcon />
                            </IconButton>
                            {/* <IconButton onClick = {() => { Del() }} sx={{color:'black'}}  style={{padding:'0'}} >
                                <DeleteIcon />
                            </IconButton> */}
                        </>
                    }
                </>
            }
        </div>

    );
}



