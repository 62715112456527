import React, { useState, useContext, useEffect } from 'react';
import Button from '../buttons/Button'
import GenericFields from '../genericFields/GenericFields'
import styles from './Form.module.css'
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { blue } from '@material-ui/core/colors';
import { GeneralFetch } from '../../api/generalFetch/generalFetch';
import { useNavigate } from 'react-router-dom';
import { AppLocation, UpdateItems } from '../../store';
import { useRecoilState } from 'recoil';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonProgress: {
        color: blue,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

function Form({ objects, resource }) {
    const [location,] = useRecoilState(AppLocation)
    const [zerar, setZerar] = useState(false)
    const [infos, setInfos] = useState({});

    const navigate = useNavigate();

    let endpoint = null

    const [selected, setSelected] = useState([]);

    const [dados,] = useRecoilState(UpdateItems)


    const { FetchData, load } = GeneralFetch({})

    const classes = useStyles();

    function updateInfosItem(key, value) {
        setZerar(false)
        setInfos({ ...infos, [key]: value });
    }

    const handleReset = () => {
        Array.from(document.querySelectorAll("input")).forEach(
            input => (input.value = " ")
        );
    };

    useEffect(() => {
        objects?.map((object) => {
            setInfos(infos => ({ ...infos, [object.key]: dados?.data?.[object.key] || "" }));
        })

        if (dados.data) {
            setInfos(infos => ({ ...infos, operation: 'update' }));
            setInfos(infos => ({ ...infos, id: dados.data.id }));
            setInfos(infos => ({ ...infos, host: location }));
        } else {
            setInfos(infos => ({ ...infos, operation: 'new' }));
        }

        return () => setInfos({})
    }, [objects])

    useEffect(() => {
        handleReset();
        return () => {
            handleReset();
        }
    }, [objects, resource])

    function SaveData() {
        let serversIds = []
        switch (resource) {
            case "server":
                endpoint = 'server-register'
                break;
            case "container":
                endpoint = 'container-register'
                break;
            case "user":
                selected?.map(val => serversIds.push(val.id))
                infos['objects'] = serversIds
                endpoint = 'user-register'
                break;
            default:
                endpoint = 'app-register'
                break;
        }
        (async () => {
            let response = await FetchData(infos, endpoint, 'POST', false, null)
            if (response == 1) {
                setInfos({})
                setZerar(true)
                if (endpoint == 'app-register') {
                    navigate('/dashboard/lista')
                }
            }
        })()
    }

    return (
        <React.Fragment>
            <form >
                {objects?.map((object) => {

                    return (
                        <GenericFields
                            key={object.key}
                            label={object.label}
                            type={object.valueType}
                            value={zerar ? '' : infos?.[object.key]}
                            options={object?.options}
                            selected={selected}
                            setSelected={setSelected}
                            dados={dados}
                            infos={infos}
                            setInfos={setInfos}
                            setZerar={setZerar}
                            zerar={zerar}
                            onChange={(e) => {
                                updateInfosItem(object.key, e.target?.value?.replace(object.regex, object.replace));
                            }}
                        />
                    )
                })}

                <div className={styles.btn}>
                    <div className={classes.wrapper}>
                        <Button
                            label="Salvar"
                            type='reset'
                            variant="contained"
                            color="primary"
                            disabled={load}
                            onClick={() => { SaveData() }}
                        />
                        {load && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </div>
                </div>
            </form>
        </React.Fragment>
    )
}
export default Form