import React, { useEffect } from 'react';
import Popper from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import { CircularProgress, Fab } from '@material-ui/core';
import styles from './popper.module.css'
import { useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useNavigate } from 'react-router-dom';
import { GeneralFetch } from '../../api/generalFetch/generalFetch';
import { Popconfirm } from 'antd';

export default function Popperr({ row, state, setState, legenda = true, id, estado, acesso }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const [placement, setPlacement] = useState();
    const [operacao, setOperacao] = useState(true);
    const { FetchData, load } = GeneralFetch()
    const navigate = useNavigate();

    useEffect(() => {
        if (state) {
            let ObjectStatus = {};
            ObjectStatus[id] = estado == 'Activo' ? 1 : 0
            setState(state => ({
                ...state,
                ...ObjectStatus
            }));
        }
    }, [id])

    const handleClose = () => {
        setAnchorEl(null);
    };

    function text() {
        if (state?.[id] == 1)
            return 'Tem certeza que pretende desativar o/a ' + row?.name + ' ?'
        else
            return 'Tem certeza que pretende activar o / a ' + row?.name + ' ? '
    }

    function description() {
        if (state?.[id] == 1)
            return 'O user não terá mais acesso ao sistema!'
    }

    const handleClick = (newPlacement) => (event) => {
        setAnchorEl(event.currentTarget);
        setOpen((prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
        setOperacao(!operacao)
    };

    function deactivateUser() {
        console.log("bla bla")
        setOpen(false);
        let value = state?.[id] == 1 ? 0 : 1;

        (async () => {
            const request = {
                user_id: id,
                estado: value
            }
            let response = await FetchData(request, 'deactivateUser', 'POST', false, null)
            if (response.success) {
                setState({ ...state, [id]: value });
            }
        })()
    }

    return (
        <div style={{ width: '100%' }} >
            {!load ? <Popper open={open} anchorEl={anchorEl} placement={placement} transition >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={100} >
                        <Paper className={styles.paper} >
                            {legenda ?
                                null
                                :
                                <Menu
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                >
                                    <div style={{ margin: '-7px 0 -7px -15px' }} >
                                        {acesso == 'normal' ? <MenuItem onClick={() => { navigate('/dashboard/utilizadores/editar') }} >Privilegios</MenuItem> : ''}
                                        <Popconfirm
                                            placement="left"
                                            title={text}
                                            description={description}
                                            onConfirm={deactivateUser}
                                            okText="Sim"
                                            cancelText="Nao"
                                            zIndex={50000}
                                        >
                                            <MenuItem >{state?.[id] == 1 ? 'Desativar' : 'Activar'}</MenuItem>
                                        </Popconfirm>
                                    </div>
                                </Menu>
                            }
                        </Paper>
                    </Fade>
                )}
            </Popper> : ''}

            {legenda ?
                <div style={{ display: 'flex', float: 'right', marginRight: '4%', marginTop: '2%', }}>
                    <Fab
                        className={styles.button}
                        variant="extended"
                        size="medium"
                        color="primary"
                        aria-label="add"
                        onClick={handleClick('left')}
                    >
                        {operacao ? 'Legenda' : <CloseIcon />}
                    </Fab>
                </div>
                :
                <div style={{ justifyContent: 'center', display: 'flex', alignItems: 'center', cursor: 'pointer' }} >
                    {load ? <CircularProgress size={20} />
                        : <MoreHorizIcon onClick={handleClick('left')} />}
                </div>
            }
        </div>
    );
}