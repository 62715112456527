import { notification } from 'antd';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { Alerta } from '../../store';

const Alert = () => {
    const [api, contextHolder] = notification.useNotification();
    const [alert,] = useRecoilState(Alerta)

    useEffect(() => {
        if (alert.msg) {
            api[alert.type]({
                message: alert.type.toUpperCase(),
                description: alert.msg,
                style: {
                    width: 550,
                },
            });
        }
    }, [alert])

    return (
        <>
            {contextHolder}
        </>
    );
};
export default Alert;