import React from 'react'
import ReactApexChart from 'react-apexcharts'
import { chartData } from './chatdata'
import ReactEcharts from "echarts-for-react"

function Chart(data) {
  const { options } = chartData(data)

  return (
    <div>{
      data.data[0] == 0 ?
        <div style={{ display: 'flex', placeContent: 'center', alignItems: 'center' }}>Sem dados para mostrar</div>
        :
        <ReactEcharts
          option={options}
          style={{ width: "82vw", height: "81vh" }}
        ></ReactEcharts>
    }</div>
  )
}

export default Chart