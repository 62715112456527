import { TableCell } from "@mui/material"
import SwitchOrEdit from "../../components/switch/switch"
import PopperOrSwitch from "./PopperOrSwitch"
import ChartPopover from "../../components/chartModal/ChartPopover";

export default function ReturnValue({ infos, row, value, obj, id, status, state, setState, data, setData }) {

    function Component() {
        if (obj == 'app' || obj == 'container' || obj == 'server') {
            return (
                <TableCell padding='none' align='center' style={{ fontSize: '14px', padding: '8.5px' }}  >
                    {(value == 'url' || value == 'URL') ?
                        <a href={row[value]} target="_blank" style={{ color: 'black', textDecoration: 'none' }} > {row[value]}</a >
                        : value == 'estado' ?
                            <SwitchOrEdit id={id} status={status} state={state} setState={setState} obj={obj} />
                            : value == 'accoes' ?
                                <PopperOrSwitch obj={obj} objID={row.id} rowID={id} data={data} setData={setData} row={row} />
                                : row[value]}
                </TableCell>
            )
        } else {
            return (
                <TableCell padding='none' align='center' style={{ fontSize: '14px', padding: '8.5px' }}  >
                    {value == 'accoes' ? obj == 'report' ?
                        <ChartPopover nome={row.nome} id={id} tipo={infos.Tipo} />
                        :
                        <PopperOrSwitch obj={obj} objID={row.id} rowID={id} data={data} setData={setData} row={row} state={state} setState={setState} />
                        :
                        value == 'estado' && state?.[id] == 1 ? "Activo" : value == 'estado' && state?.[id] == 2 ? 'Desativado' : row[value]}
                </TableCell>
            )
        }
    }

    return (
        <>
            {Component()}
        </>
    )
}
