import React, { useState } from 'react'
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import { AppBar, Toolbar } from '@mui/material';
import styles from './Layout.module.css'
import Menu from '../menu/Menu';
import { routes } from '../../routes';
import Header from '../Header/index';
import CreateUser from '../../pages/user_create/CreateUser';
import UpdateUser from '../../pages/user_update/UpdateUser';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#00559A',
    },
  },
});

function Layout() {
  const [showMenu, setShowMenu] = React.useState(true);
  const { rotas } = routes()
  const navigate = useNavigate()

  React.useEffect(() => {
    if (rotas === null || rotas === undefined) {
      navigate('/')
    }
  }, [rotas])

  return (
    <React.Fragment>
      {rotas === null ? null :
        <div className={styles.layout}>
          <div>
            <ThemeProvider theme={theme}>
              <AppBar style={{ background: '#5C80BC' }} color='primary' position='relative'>
                <Toolbar>
                  <Header setShowMenu={setShowMenu} showMenu={showMenu} />
                </Toolbar>
              </AppBar>
            </ThemeProvider>
          </div>
          <div className={styles.body}>
            <div className={showMenu ? styles.withMenu : styles.withoutMenu}>
              <Menu setShowMenu={setShowMenu} showMenu={showMenu} />
              <Routes>
                {
                  rotas[0].map((routes, index) =>
                    <Route key={index} path={routes.path} element={routes.Component}></Route>
                  )
                }
                <Route element={<CreateUser />} path="utilizadores/registar" />
                <Route element={<UpdateUser />} path="utilizadores/editar" />
              </Routes>
            </div>
          </div>
        </div>
      }
    </React.Fragment>
  );
}

export default Layout;