import React from "react";
import Form from "../../components/form/Form";
import { Divider, Paper } from "@mui/material";
import styles from './CreateUser.module.css'
import { Fab } from "@mui/material";
import { Link } from "react-router-dom";
import { List } from "@material-ui/icons";
import { user } from '../../utils/variables/user'

function CreateUser() {
    return (
        <div className={styles.sign}>
            <Paper elevation={3} className={styles.paper}>
                <div style={{ textAlign: 'center', fontSize: '22px' }}> Registo - Utilizadores</div>
                <Divider style={{ margin: '5px 0' }} />
                <Form objects={user} resource='user' />
            </Paper>
            <Link to='/dashboard/utilizadores'>
                <Fab color="primary" aria-label="add"
                    style={{ position: 'fixed', bottom: '30px', right: 16, borderRadius: '100%', backgroundColor: '#00559A' }}
                >
                    <List style={{ color: '#fff' }} />
                </Fab>
            </Link>
        </div>
    )
}

export default CreateUser;