import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
    container: {
        textTransform: 'capitalize',
        display: 'grid',
        gridTemplateRows: '20% 80%',
        alignItems: 'center',
        marginLeft: '5%',
    },
    label: {
        marginBottom: '0.5%'
    }
}));

export default function Selects({ label, values, placeholder, infos, setInfos, show = true, variant }) {
    const classes = useStyles();

    const onChange = (e) => {
        setInfos({ ...infos, [label]: e.target.value, });
    }

    return (
        <div className={classes.container}>
            {show && <label className={classes.label}>{label}</label>}
            <FormControl variant={variant || 'outlined'} size='small'>
                <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={infos?.[label]}
                    placeholder={placeholder}
                    onChange={onChange}
                >
                    {
                        values?.map((val, id) => {
                            return <MenuItem key={id} value={val.key}>{val.value}</MenuItem>
                        })
                    }
                </Select>
            </FormControl>
        </div>
    );
}