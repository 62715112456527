import { useState } from "react"
import { OrganizeTable } from "../../utils/tableUtils/getTableHeaders"
import { GetParams } from "./getFetchParams"
import { Alerta } from "../../store"
import { useRecoilState } from "recoil"

export function GeneralFetch() {
    //https://apps.hisplp.org/onitapi/api/
    let baseURL = 'https://apps.hisplp.org/onitapi/api/'

    const [alert, setAlert] = useRecoilState(Alerta)
    const [load, setLoad] = useState(false)
    const [data, setData] = useState([])

    const { tableHeadersAndData } = OrganizeTable({ setData })
    const { ReturnParams } = GetParams({ setLoad })

    async function FetchData(obj, endPoint, method, table, object) {

        let response = fetch(baseURL + "" + endPoint, ReturnParams(obj, method))
            .then((response) => response.json())
            .then((data) => {
                if (data.error || data.warning || data.success) {
                    setAlert(alert => ({ msg: data[Object.keys(data)[0]], type: Object.keys(data)[0] }))
                } else {
                    if (object && table) {
                        tableHeadersAndData(data, object)
                    } else if (object && !table) {
                        if (data[object]) {
                            if (data.data?.[object]) {
                                setData(data.data[object])
                            } else {
                                setData(data[object])
                            }
                        } else {
                            setData(data)
                        }
                    }
                }
                setLoad(false)
                return data || 1
            })
        return response
    }

    return { FetchData, load, data }
}