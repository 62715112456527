import React, { useEffect, useState } from "react";
import Graph from "react-graph-vis";
import { StatusChecker } from "../../api/Checker/checker";
import BasicModal from "../modal/Modal";
import { useRecoilState } from "recoil";
import { OpenModal, SearchData } from "../../store";

export function Grafo({ links, nodes, client, load, updateForm, setUpdateForm }) {
    const [status, setStatus] = useState([])
    const [seacrh, ] = useRecoilState(SearchData)
    const [LocalModal, setLocalModal] = useState({})
    const [OperationData, setOperationData] = useState("")
    const [, setOpen] = useRecoilState(OpenModal)

    useEffect(() => {
        update()
    }, [seacrh])

    useEffect(() => {
        if (updateForm) {
            let data
            if (updateForm.visibility) {
                if (updateForm.all) {
                    data = graph.nodes.map((node) => {
                        if (updateForm.cluster == node.type) {
                            return { ...node, hidden: updateForm.show };
                        } else {
                            return node
                        }
                    });
                } else {
                    data = graph.nodes.map((node) => {
                        if (updateForm.id == node.id) {
                            return { ...node, hidden: updateForm.show };
                        } else {
                            return node
                        }
                    });
                }
            } else {
                data = graph.nodes.map((node) => {
                    if (updateForm.id == node.id) {
                        return { ...node, shape: updateForm.shape };
                    } else {
                        return node
                    }
                });
            }

            const updated_data = { ...graph };
            updated_data.nodes = data;
            setGraph(updated_data);
            setLocalModal({ open: false })
        }
    }, [updateForm])


    useEffect(() => {
        if (OperationData.lastTime) {
            let data = graph.nodes.map((node) => {
                let result = 0

                status?.map((val) => {
                    if (val.app_id == node.id || val.container_id == node.id || val.server_id == node.id) {
                        switch (val.estado_id) {
                            case '1':
                                result = { ...node, status: val.motivo, color: "green" };
                                break;
                            case '2':
                                result = { ...node, status: val.motivo, color: "yellow" };
                                break;
                            case '3':
                                result = { ...node, status: val.motivo, color: "red" };
                                break;
                            default:
                                result = { ...node, status: val.motivo, color: "grey" };
                                break;
                        }
                    }
                })

                if (result === 0) {
                    if (OperationData.type == node.type && node.type != 'app') {
                        result = { ...node, status: 500, color: "red" };
                    } else {
                        result = node
                    }
                }
                return result
            });

            const updated_data = { ...graph };
            updated_data.nodes = data;
            setOperationData("")
            setGraph(updated_data);
        }
    }, [OperationData])

    useEffect(() => {
        if (!load) {
            const updated_data = { ...graph };
            updated_data.nodes = nodes;
            setGraph(updated_data);
        }
    }, [nodes])

    const [graph, setGraph] = useState({
        nodes: nodes,
        edges: links
    });

    const options = {
        interaction: {
            hover: true
        },
        layout: {
            improvedLayout: true,
            clusterThreshold: 15
        },
        physics: {
            forceAtlas2Based: {
                gravitationalConstant: -50,
                centralGravity: 0.006,
                springLength: 110,
                springConstant: 0.18,
                avoidOverlap: 1.5
            },
            solver: 'forceAtlas2Based',
            stabilization: {
                iterations: 1000,
            }
        },
        autoResize: true,
        nodes: {
            size: 25,
            font: {
                size: 12,
            }
        },
        edges: {
            length: 110,
        }
    };

    function update() {
        const data = graph.nodes.map((node) => {
            if (seacrh && node.label.substring(0, seacrh.length).toLowerCase() === seacrh.toLowerCase()) {
                return { ...node, size: 50 };
            }
            return { ...node, size: 28 };
        });

        const updated_data = { ...graph };
        updated_data.nodes = data;
        setGraph(updated_data);
    }

    const events = {
        select: function (event) {
            graph.nodes.map((node) => {
                if (event.nodes[0] === node.id) {
                    if (client == 'dash') {
                        setLocalModal({
                            id: node.id,
                            open: true,
                            component: 'node',
                            nome: node.label,
                            status: node.status,
                            type: node.type,
                            url: node.url,
                            host_id: node.node_pai,
                            fqdn: node.fqdn,
                            upstream: node.upstream,
                            dns: node.dns,
                            ip: node.ip,
                            hidden: node.hidden
                        })
                    } else if (client == 'ssl') {
                        setOpen({ open: true, component: 'ssl_details', app: node })
                    }
                }
            });
        },
    };

    return (
        <div style={{ overflow: "hidden" }}>
            <StatusChecker setOperationData={setOperationData} setStatus={setStatus} />
            <Graph
                graph={graph}
                options={options}
                events={events}
                style={{ height: "90vh" }}
            />
            {LocalModal.open ? <BasicModal setUpdateForm={setUpdateForm} ModalData={LocalModal} setModalData={setLocalModal} /> : ""}
        </div>
    );
}
