export const lineChartData = (data) => {

    var options = {
        grid: { top: 20, right: 30, bottom: 30, left: 60 },
        xAxis: {
            type: "category",
            data: data?.days?.length > 0 ? [...data?.days] : ["Sem acontecimentos nas datas selecionadas!"]
        },
        yAxis: {
            type: "value",
            axisLabel: {
                formatter: function (value) {
                    switch (value) {
                        case 1:
                            return "Up";
                        case 2:
                            return "Saturado";
                        case 3:
                            return "Down";
                        case 4:
                            return "Off";
                    }
                },
            },
            splitNumber: 4,
            min: 0,
            max: 4
        },
        series: [
            {
                data: data?.data?.length > 0 ? [...data?.data] : [],
                type: "line",
                smooth: true
            }
        ],
        tooltip: {
            trigger: "axis"
        }
    };


    return { options: options }
}