import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from './Transfer.module.css'
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';


const StyledCardHeader = styled(CardHeader)(({ theme }) => ({
  '& .MuiCardHeader-title': {
    fontSize: '18px !important',
  }
}));


function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

export default function TransferList({ data, setServers, user, load }) {
  const [checked, setChecked] = React.useState([]);
  const { all, mine } = data
  const [left, setLeft] = React.useState([]);
  const [right, setRight] = React.useState([]);
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);
  const [toogle, setToogle] = React.useState(true);

  useEffect(() => {
    if (data.mine || data.all) {
      setLeft(all)
      setRight(mine)
    }
  }, [data])


  useEffect(() => {
    let servers = { 'servers': {}, 'user_id': user }
    servers['servers'] = right
    servers['user_id'] = user
    setServers(servers)
  }, [toogle])

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };


  const customList = (title, items) => (
    items &&
    <Card className={styles.paper} >
      <StyledCardHeader
        sx={{ px: 2, py: 1 }}
        title={title}
      />
      <Divider />
      <List
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '200px'
        }}
        dense
        component="div"
        role="list"
      >
        {items.length > 0 ? items.map((value) => {
          const labelId = `transfer-list-item-${value.id}-label`;

          return (
            <ListItem
              key={value}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                  style={{ marginLeft: '20px' }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value.nome} />
            </ListItem>
          );
        })
          :
          <ListItem
            role="listitem"
            button
          >
            <ListItemText primary="Sem dados!" />
          </ListItem>
        }
      </List>
    </Card>
  );

  return (
    <>
      {load ?
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '75vh' }}>
          <CircularProgress color="primary" />
        </div>
        :
        <Grid container className={styles.gridCont}>
          <div style={{ marginRight: '15px' }}>
            <Grid item>{customList('Por conceder', left)}</Grid>
          </div>
          <Grid item>
            <Grid container direction="column" alignItems="center">
              <Button
                className={styles.buttons}
                variant="outlined"
                size="small"
                onClick={() => { handleAllRight(); setToogle(!toogle) }}
                disabled={left?.length === 0}
                aria-label="move all right"
              >
                ≫
              </Button>
              <Button
                className={styles.buttons}
                variant="outlined"
                size="small"
                onClick={() => { handleCheckedRight(); setToogle(!toogle) }}
                disabled={leftChecked.length === 0}
                aria-label="move selected right"
              >
                &gt;
              </Button>
              <Button
                className={styles.buttons}
                variant="outlined"
                size="small"
                onClick={() => { handleCheckedLeft(); setToogle(!toogle) }}
                disabled={rightChecked.length === 0}
                aria-label="move selected left"
              >
                &lt;
              </Button>
              <Button
                className={styles.buttons}
                variant="outlined"
                size="small"
                onClick={() => { handleAllLeft(); setToogle(!toogle) }}
                disabled={right?.length === 0}
                aria-label="move all left"
              >
                ≪
              </Button>
            </Grid>
          </Grid>
          <div style={{ marginLeft: '15px' }} >
            <Grid item>{customList('Concedidos', right)}</Grid>
          </div>
        </Grid>
      }
    </>
  );
}