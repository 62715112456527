import { Início, Lista, Registo, Relatorios } from '../pages/pages'
import {
    AssessmentOutlined, CreateOutlined, DashboardOutlined,
    FormatListBulletedOutlined, PersonOutline, SecurityOutlined
} from '@material-ui/icons';
import { isAuthenticated } from '../auth/auth.js'
import UserList from '../pages/user_list/User';
import SSL from '../pages/certificados_ssl/Ssl';

const routesList = [
    {
        label: "Início",
        path: "",
        access: 'normal',
        icon: <DashboardOutlined />,
        Component: <Início />
    },
    {
        label: "Registo",
        path: "registo",
        access: 'admin',
        icon: <CreateOutlined />,
        Component: <Registo />
    },
    {
        label: "Lista",
        path: "lista",
        access: 'admin',
        icon: <FormatListBulletedOutlined />,
        Component: <Lista />
    },
    {
        label: "Certificados SSL",
        path: "ssl",
        access: 'admin',
        icon: <SecurityOutlined />,
        Component: <SSL />
    },
    {
        label: "Relatório",
        path: "relatorios",
        access: 'normal',
        icon: <AssessmentOutlined />,
        Component: <Relatorios />
    },
    {
        label: "Utilizadores",
        path: "utilizadores",
        access: 'admin',
        icon: <PersonOutline />,
        Component: <UserList />
    }
]

export function routes() {
    let visibleRoutes = []
    if (isAuthenticated() === 'admin') {
        visibleRoutes.push(routesList);
    } else if (isAuthenticated() === 'normal') {
        const rotas = routesList.filter(routesList => routesList.access === isAuthenticated())
        visibleRoutes.push(rotas)
    } else {
        visibleRoutes = null
    }

    return {
        rotas: visibleRoutes
    }
}
