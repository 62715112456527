import { Paper } from "@material-ui/core";
import { Divider } from "@mui/material";
import React, { useState } from "react";
import Buttons from "../../components/buttons/Button";
import TransferList from "../../components/transfer/Transfer";
import styles from './UpdateUser.module.css'
import { useEffect } from "react";
import { ServerToUpdate } from '../../utils/serversToUpdate/servers'
import { GeneralFetch } from "../../api/generalFetch/generalFetch";
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from "recoil";
import { UserUpdate } from "../../store";

function UpdateUser() {
    const [selectedUser,] = useRecoilState(UserUpdate)
    const [OrganizedData, setOrganizedData] = useState([])
    const { organize } = ServerToUpdate({ setOrganizedData })
    const [servers, setServers] = useState([])
    const { FetchData, data, load } = GeneralFetch()
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            const request = {
                user_id: selectedUser.id
            }
            await FetchData(request, 'getUserServers', 'POST', false, 'Users')
        })()
    }, [])


    function Update() {
        organize(data, servers)
        if (OrganizedData.length > 0) {
            (async () => {
                const request = {
                    user_id: selectedUser.id,
                    remove: OrganizedData[1],
                    add: OrganizedData[0]
                }
                await FetchData(request, 'updateUserSees', 'POST', false, null)
            })()
        }
    }
    return (
        <div className={styles.container}>
            <div>
                <Paper elevation={2} className={styles.paper} sx={{ bg: 'whitesmoke' }}>
                    <div className={styles.userdetails}>
                        <br />
                        <h4> Actualização dos Privilegios - {selectedUser.name} {selectedUser.apelido} </h4>
                    </div>
                    <Divider />
                    <div className={styles.privs}>
                        <TransferList load={load} data={data} setServers={setServers} user={selectedUser.id} />
                    </div>
                    <div className={styles.buttons} >
                        <Buttons variant='contained' color='primary' label='Actualizar' onClick={() => Update()} />
                        <Buttons style={{ marginLeft: '10px', width: '95px' }} variant='contained' color='secondary' label='Voltar' onClick={() => navigate(-1)} />
                    </div>
                </Paper>
            </div>
        </div>
    )
}

export default UpdateUser;