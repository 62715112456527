import React from "react";
import { Route, Routes, HashRouter } from "react-router-dom";
import { MainLayout, Início, Lista, Registo, Relatorios, Login, CreateUser, UpdateUser, User, Reset, SSL } from "../pages/pages"


const NotFound = () => {
  return (
    <h1 style={{ textAlign: 'center' }}>Page Not Found</h1>
  )
}

const Rotas = () => (
  <HashRouter>
    <Routes>
      <Route exact path="/" element={<Login />} />
      <Route exact path="/reset_password" element={<Reset />} />
      <Route element={<MainLayout />} path="/dashboard">
        <Route element={<Início />} path="home" />
        <Route element={<Lista />} path="lista" />
        <Route element={<Registo />} path="registo" />
        <Route element={<SSL />} path="ssl" />
        <Route element={<Relatorios />} path="relatorios" />
        <Route element={<UpdateUser />} path="utilizadores" />
        <Route element={<CreateUser />} path="utilizadores/registar" />
        <Route element={<User />} path="utilizadores/editar" />
      </Route>
      <Route element={<NotFound />} path="*" />
    </Routes>
  </HashRouter>
);

export default Rotas;