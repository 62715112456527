import React, { useCallback } from "react";
import { OrganizeGraphData } from "../../components/graph/graphdata";
import { Grafo } from "../../components/graph/graph";
import { CircularProgress } from "@material-ui/core";
import { legenda } from "../../utils/variables/legenda";
import Legenda from "../../components/legenda/Legenda";
import Button from "../../components/buttons/Button";
import { GeneralFetch } from "../../api/generalFetch/generalFetch";
import { useState } from "react";

function SSL() {
  const [error, setError] = React.useState(false)
  const { ssl } = legenda()
  const { FetchData, load } = GeneralFetch()
  const { links, nodes } = OrganizeGraphData({ setError, client: 'ssl', load })
  const [, updateState] = useState();
  const handleForceupdateMethod = useCallback(() => updateState({}), []);

  async function update() {
    let response = await FetchData(null, 'refreshSSLcertificate', 'GET')
    if (response) {
    } else {
      setTimeout(800)
    }
    handleForceupdateMethod();
  }

  function Load() {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90vh' }}>
        <CircularProgress color="primary" />
      </div>
    )
  }

  return (
    <React.Fragment>
      {
        (nodes.length > 0 || (nodes == 1) || error) ?
          <div>
            {
              error ?
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90vh' }}>
                  <h1 style={{ color: 'red' }} >Ocorreu um erro na busca dos dados</h1>
                </div>
                :
                load ?
                  <Load />
                  :
                  nodes?.length > 0 ?
                    <div>
                      <Grafo links={links} nodes={nodes} client='ssl' load={load} />
                      <Legenda estados={ssl} />
                    </div>
                    :
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90vh' }}>
                      <h1>Não há dados para mostrar</h1>
                    </div>
            }

            <div style={{ position: 'fixed', bottom: '15px', right: 15 }} >
              <Button label='Actualizar' color='primary' variant='contained' size='large' onClick={() => update()} />
            </div>
          </div>
          :
          <Load />
      }

    </React.Fragment>
  )
}

export default SSL;