let ipRegex = /[^0-9,.]/g;
let nomeRegex = /[^a-z,^A-Z,^0-9]/g;
let fqdnRegex = /[^a-z,.,:,/]/g;
let upstreamRegex = /[^a-z,^A-Z,^0-9,@,.,:,/]/g;


export const container = [
    {
        "key":"ip",
        "label": "IP",
        "valueType": "text",
        "regex":ipRegex,
        "replace":""
    },
    {
        "key": "server_id",
        "label": "Servidor",
        "valueType": "select"
    },
    {
        "key":"nome",
        "label": "Nome",
        "valueType": "text",
        "regex":nomeRegex,
        "replace":"_"
    },
    {
        "key":"fqdn",
        "label": "FQDN",
        "valueType": "text",
        "regex":fqdnRegex,
        "replace":"_"
    },
    {
        "key":"upstream",
        "label": "Upstream",
        "valueType": "text",
        "regex":upstreamRegex,
        "replace":"_"
    }
]