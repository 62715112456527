import React from 'react'
import Input from './fields/Input.js'
import Select from './fields/Select.js'
import MultipleSelect from './fields/Multiple.js'
import RadioButtonsGroup from './fields/RadioButtons.js'
import DataPicker from '../filter/period/DataPicker.js'


function GenericFields(props) {
  const { label, type, onChange, infos, value, selected, setSelected, options,
    zerar, setZerar, setInfos, dados, key } = props

  switch (type) {
    case 'number':
      return (
        <Input
          type="number"
          label={label}
          defaultValue={1}
          min={1}
          onChange={onChange}
          value={value}
        />
      )

    case 'email':
      return (
        <Input
          type="email"
          label={label}
          onChange={onChange}
          value={value}
        />
      )

    case 'password':
      return (
        <Input
          type="password"
          label={label}
          defaultValue={1}
          min={1}
          onChange={onChange}
          value={value}
        />
      )

    case 'text':
      return (
        <Input
          type="text"
          label={label}
          onChange={onChange}
          value={value}
        />
      )

    case 'select':
      return (
        <Select
          zerar={zerar}
          setZerar={setZerar}
          key={key}
          label={label}
          infos={infos}
          setInfos={setInfos}
          dados={{ container: dados?.data?.['container'], servidor: dados?.data?.['servidor'], host_id: dados?.data?.['host_id'] }}
        />
      )

    case 'multiselect':
      return (
        <MultipleSelect
          label={label}
          selected={selected}
          setSelected={setSelected}
          infos={infos}
        />
      )

    case 'radio':
      return (
        <RadioButtonsGroup
          label={label}
          options={options}
          onChange={onChange}
        />
      )

    default: return null
  }
}

export default GenericFields