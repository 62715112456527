export const legenda = () =>{

    const dashboard = [
        { "color": "red", "state": "Problema" },
        { "color": "gray", "state": "Desligado" },
        { "color": "green", "state": "Funcionando" },
        { "color": "yellow", "state": "Sobrecarregado" },
    ]

    const ssl = [
        { "color": "green", "state": "Ok" },
        { "color": "red", "state": "Expirado" },
        { "color": "yellow", "state": "A expirar" },
    ]

    return {
        dashboard:dashboard,
        ssl:ssl
    }
}