import React from "react";
import styles from './Reset.module.css'
import { Paper } from "@mui/material";
import Input from "../../components/genericFields/fields/Input";
import Buttons from "../../components/buttons/Button";
import { useState } from "react";
import { useEffect } from "react";
import { GeneralFetch } from "../../api/generalFetch/generalFetch";
import { useNavigate } from "react-router-dom";


const reset = [
    { "key": "password", "label": "Senha", "valueType": "password" },
    { "key": "confirm_password", "label": "Confirmar", "valueType": "password" }
]

function Reset() {
    const [match, setMatch] = useState(true)
    const [infos, setInfos] = useState({});
    const [password, setPassword] = useState({ 'password': null, 'user_id': localStorage.getItem('user_id') })
    const { FetchData } = GeneralFetch()
    const navigate = useNavigate();
    let novo = localStorage.getItem('novo');

    function updateInfosItem(key, value) {
        setInfos({ ...infos, [key]: value });
    }

    const handlePasswordChange = () => {
        setPassword({ ...password, ['password']: infos['password'] })
    }

    useEffect(() => {
        if (infos['password'] !== infos['confirm_password'])
            setMatch(false)
        else {
            setMatch(true)
            handlePasswordChange()
        }
    }, [infos])

    const handleClick = () => {
        (async () => {
            let response = await FetchData(password, 'updatePassword', 'POST', false, null)
            if (response != 0) {
                navigate('/dashboard')
            }
        })()
    }

    return (
        <div className={styles.login}>
            <Paper className={styles.card}>
                <div className={styles.text}>
                    <h2>Alterar Senha</h2>
                </div>
                <div className={styles.inputs}>
                    {
                        reset.map((reset) => {
                            return <Input key={reset.key} type={reset.valueType} label={reset.label}
                                onChange={(e) => { updateInfosItem(reset.key, e.target.value); }} />
                        })
                    }
                </div>
                {
                    match ? null :
                        <div className={styles.error}>
                            <small>As senhas não correspodem</small>
                        </div>
                }
                <div className={styles.btns}>
                    <Buttons onClick={() => handleClick()} label='Confirmar' variant='contained' color='primary' size='large' />
                    {   novo == 0 ?
                        <Buttons onClick={() => navigate('/dashboard')} label='Cancelar' variant='contained' color='secondary' size='large' />
                        : null
                    }
                </div>
            </Paper>
        </div>
    )
}

export default Reset;