import Popperr from "../../components/popper/popper";
import SwitchOrEdit from "../../components/switch/switch";

export default function PopperOrSwitch({ obj, objID, rowID, data, setData, row, state, setState }) {
    return (
        <>
            {(obj !== 'report') && localStorage.getItem('acesso') === 'admin' ?
                obj == 'users' ?
                    <Popperr row={row} state={state} setState={setState} legenda={false} id={rowID} estado={row.estado} acesso={row.acesso} />
                    :
                    <SwitchOrEdit obj={obj} user={false} objID={objID} rowID={rowID} data={data} setData={setData} row={row} />
                : null
            }
        </>
    )
}