import React, { useEffect } from "react";
import { CircularProgress, IconButton } from "@material-ui/core";
import { GeneralFetch } from "../../api/generalFetch/generalFetch";
import LineChart from "../chart/lineChart.js/LineChart";
import { Modal } from 'antd';
import { useState } from 'react';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import DataPicker from "../filter/period/DataPicker";
import SsidChartOutlinedIcon from '@mui/icons-material/SsidChartOutlined';

const style = { width: '95%', height: '100px', borderRadius: '7px', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }

const datas = [
    { 'key': 'dataI', 'label': 'Inicio' },
    { 'key': 'dataF', 'label': 'Fim' }
]

const ChartPopover = ({ nome, id, tipo }) => {
    const [modal2Open, setModal2Open] = useState(false);
    const { FetchData, data, load } = GeneralFetch()
    const [estados, setEstados] = useState({ OK: 0, Problemas: 0, Saturado: 0, Desligado: 0 })
    const [infos, setInfos] = useState({ dataI: '', dataF: '' });

    function convert(str) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }

    useEffect(() => {
        if (modal2Open) {
            (async () => {
                await FetchData(infos, 'getStatusChartData/' + id + '/' + tipo, 'POST', false, 'Servers')
            })()
        }
    }, [modal2Open, infos])

    useEffect(() => {
        let helper = {}
        if (data?.count?.length > 0) {
            data?.count?.map((val) => {
                if (val.id == data.id) { helper[val.estado] = val.totalOcorrencias; helper.nome = val.nome }
            })
            setEstados(estados => ({ ...estados, ...helper }))
        } else {
            setEstados({ ...estados, Up: 0, Down: 0, Saturado: 0, Desligado: 0 })
        }
    }, [data])

    function Search() {
        return (
            <>
                {
                    (infos[datas[0].key] == '' && infos[datas[1].key] == '') ?
                        <span style={{ marginBottom: '-70px' }} >Dados dos ultimos 30 dias</span>
                        : (infos[datas[0].key] && infos[datas[1].key]) ?
                            <span style={{ marginBottom: '-70px' }} >Dados entre os dias {convert(infos[datas[0].key])} e {convert(infos[datas[1].key])}</span>
                            : infos[datas[0].key] ?
                                <span style={{ marginBottom: '-70px' }} >Dados a partir do dia {convert(infos[datas[0].key])}</span>
                                : infos[datas[1].key] &&
                                <span style={{ marginBottom: '-70px' }} >Dados a ate o dia {convert(infos[datas[1].key])}</span>
                }
                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '25px 0 10px 0' }} >
                    <label style={{ marginTop: '7px', fontSize: '17px', textTransform: 'capitalize' }} >{nome} </label>

                    <div style={{ display: 'grid', gridTemplateColumns: '10% 40% 40%', width: '50%', justifyContent: 'space-between' }}>
                        <label style={{ marginTop: '7px', fontSize: '17px' }} >Datas: </label>
                        {
                            datas.map((datas) => {
                                return <DataPicker keyy={datas.key} label={datas.label} infos={infos} setInfos={setInfos} />
                            })
                        }
                    </div>
                </div>
            </>
        )
    }

    console.log(estados)
    return (
        <>
            <IconButton style={{ width: '37px', height: '37px', margin: '-7.3px' }} onClick={() => { setModal2Open(true); setInfos({ dataI: '', dataF: '' }) }}>
                <SsidChartOutlinedIcon />
            </IconButton>
            <Modal
                centered
                open={modal2Open}
                onOk={() => setModal2Open(false)}
                onCancel={() => setModal2Open(false)}
                footer={[]}
                width={730}
            >
                {
                    load ? <div style={{ height: '460px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} ><CircularProgress /></div> :
                        <>
                            <Search />
                            <hr></hr>
                            <LineChart data={data} />
                            <div style={{ display: 'grid', gridTemplateColumns: '25% 25% 25% 25%', justifyContent: 'space-evenly', margin: '20px' }} >
                                <div style={{ ...style, backgroundColor: '#D3F8E2' }} >
                                    <ThumbUpOffAltIcon style={{ fontSize: '33px' }} />
                                    <span>UP</span>
                                    <span style={{ fontSize: '17px' }} >{estados?.Up || 0}</span>
                                </div>
                                <div style={{ ...style, backgroundColor: '#F694C1' }} >
                                    <ThumbDownOffAltIcon style={{ fontSize: '33px' }} />
                                    <span>Down</span>
                                    <span style={{ fontSize: '17px' }} >{estados?.Down || 0}</span>
                                </div>
                                <div style={{ ...style, backgroundColor: "#EDE7B1" }} >
                                    <ReportProblemOutlinedIcon style={{ fontSize: '33px' }} />
                                    <span>Saturado</span>
                                    <span style={{ fontSize: '17px' }} >{estados?.Saturado || 0}</span>
                                </div>
                                <div style={{ ...style, backgroundColor: "#9BA7C0" }} >
                                    <NotInterestedIcon style={{ fontSize: '33px' }} />
                                    <span>Off</span>
                                    <span style={{ fontSize: '17px' }} >{estados?.Desligado || 0}</span>
                                </div>
                            </div>
                        </>
                }
            </Modal>
        </>
    );
}

export default ChartPopover