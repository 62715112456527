let nomeRegex = /[^a-z,^A-Z,^0-9]/g;
let urlRegex = /[^a-z,^A-Z,^0-9,.,:,/]/g;

export const app = [
    {
        "key": "nome",
        "label": "nome",
        "valueType": "text",
        "regex": nomeRegex,
        "replace": "_"
    },
    {
        "key": "url",
        "label": "Url",
        "valueType": "text",
        "regex": urlRegex,
        "replace": ""
    },
    {
        "key": "host",
        "label": "Local",
        "options": [
            {
                "name": "Servidor",
                "key": "Servidor"
            },
            {
                "name": "Container",
                "key": "Container"
            }
        ],
        "valueType": "radio"
    },
    {
        "key": "container",
        "valueType": "select"
    }
]