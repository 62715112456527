let emailRegex = /[^a-z,^0-9,.,@]/g;
let nomeRegex = /[^a-z,^A-Z, ]/g;

export const user = [
    {   
        "key":"nome", 
        "label":"Nome", 
        "valueType":"text",
        "regex":nomeRegex,
        "replace":""
    },
    {
        "key":"apelido", 
        "label":"Apelido", 
        "valueType":"text",
        "regex":nomeRegex,
        "replace":""
    },
    {
        "key":"email", 
        "label":"Email", 
        "valueType":"email",
        "regex":emailRegex,
        "replace":""
    },
    {
        "key":"objects", 
        "label":"Servidor", 
        "valueType":"multiselect"
    },
    {
        "key":"acesso", 
        "label":"Acesso",
        "valueType":"radio", 
        "options":[
            {
                "key":"admin", 
                "name":"administrador"
            },
            {
                "key":"normal", 
                "name":"normal"
            }
    ]},
]