import React, { useState, useContext } from "react";
import styles from './Setting.module.css';
import { Close } from "@material-ui/icons";
import { Tooltip } from "@mui/material";
import Button from '../buttons/Button'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { hideNodes } from "../../utils/hideNodes/hideNodes";
import { GeneralFetch } from "../../api/generalFetch/generalFetch";
import Buttons from "../buttons/Button";
import BasicModal from "../modal/Modal";

function Setting({ hidden, setUpdateForm }) {
    const [show, setShow] = React.useState(false)
    const [visbility, setVisibilty] = useState({
        'app': hidden[0] && true,
        'server': hidden[1] && true,
        'container': hidden[2] && true
    })
    const [ModalData, setModalData] = useState({})
    const { FetchData } = GeneralFetch()
    const { returnObj } = hideNodes()

    function hide(cluster) {
        let obj = returnObj(cluster, visbility[cluster]);
        (async () => {
            let response = await FetchData(obj, 'hideClusters', 'POST', false, 'nodeIcon')
            if (response == 1) {
                setVisibilty({ ...visbility, [cluster]: !visbility[cluster] })
                setUpdateForm({ visibility: true, all: true, cluster: cluster == 'container' ? 'cont' : cluster, show: !visbility[cluster] })
            }
        })()
    }

    return (
        <React.Fragment>
            <div className={styles.container}>
                {
                    show ?
                        <React.Fragment>
                            <List
                                subheader={
                                    <ListSubheader>Clusters
                                        <Tooltip className={styles.tooltip} title='Fechar Legenda' onClick={() => setShow(!show)}>
                                            <Close style={{ fontSize: '1rem', cursor: 'pointer' }} />
                                        </Tooltip>
                                    </ListSubheader>
                                }
                            >
                                <ListItem >
                                    <ListItemText
                                        primaryTypographyProps={{
                                            fontSize: 13,
                                            fontWeight: 'thin',
                                        }}

                                        primary='Aplicacoes' />
                                    <Tooltip onClick={() => { hide('app', visbility.app); setVisibilty({ ...visbility, app: !visbility.app }) }} title={visbility.app ? 'Mostrar' : 'Esconder'}>
                                        {
                                            visbility.app ?
                                                <Visibility/>
                                                :
                                                <VisibilityOff />
                                        }
                                    </Tooltip>
                                </ListItem>

                                <ListItem >
                                    <ListItemText
                                        primaryTypographyProps={{
                                            fontSize: 13,
                                            fontWeight: 'thin',
                                        }}

                                        primary='Container' />
                                    <Tooltip onClick={() => { hide('container', visbility.container); setVisibilty({ ...visbility, container: !visbility.container }) }} title={visbility.container ? 'Mostrar' : 'Esconder'}>
                                        {
                                            visbility.container ?
                                                <Visibility />
                                                :
                                                <VisibilityOff />
                                        }
                                    </Tooltip>
                                </ListItem>

                                <ListItem >
                                    <ListItemText
                                        primaryTypographyProps={{
                                            fontSize: 13,
                                            fontWeight: 'thin',
                                        }}

                                        primary='Servidor' />
                                    <Tooltip onClick={() => { hide('server', visbility.server); setVisibilty({ ...visbility, server: !visbility.server }) }} title={visbility.server ? 'Mostrar' : 'Esconder'}>
                                        {
                                            visbility.server ?
                                                <Visibility />
                                                :
                                                <VisibilityOff />
                                        }
                                    </Tooltip>
                                </ListItem>
                                <Buttons onClick={() => { setModalData({ component: 'hidden_nodes', open: true, hidden: true }) }} label='Ocultos' variant='text' color='primary' size='small' />
                                <ListItem >
                                </ListItem>
                            </List>
                        </React.Fragment>
                        :
                        <div className={styles.setBtn}>
                            <Button
                                variant='text'
                                label='Clusters'
                                color='primary'
                                className={styles.setBtn}
                                onClick={() => setShow(!show)}
                            />
                        </div>
                }
            </div>
            {ModalData.open && <BasicModal setUpdateForm={setUpdateForm} ModalData={ModalData} setModalData={setModalData} />}
        </React.Fragment>
    )

}

export default Setting