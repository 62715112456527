import React, { useEffect, useState } from "react";
import Filter from "../../components/filter/Filter";
import styles from './Relatorios.module.css'
import { Fab } from "@mui/material";
import { BarChart, TableChart } from "@mui/icons-material";
import Tabela from '../../components/table/Tabela'
import { GeneralFetch } from "../../api/generalFetch/generalFetch"
import Chart from "../../components/chart/barChart/Chart";
import { useRecoilState } from "recoil";
import { RowsPerPage } from "../../store";
import useDebounce from "../../utils/search/searchDebounce";

const Relatorio = () => {
    const [toogle, setToogle] = useState(true)
    const [tabledata, setTableData] = useState([]);
    const [chartdata, setChartData] = useState({});
    const [page, setPage] = useState(1);
    const { FetchData, data, load } = GeneralFetch()
    const [perPage,] = useRecoilState(RowsPerPage)
    const [infos, setInfos] = useState({ Estado: 0, Tipo: 'app' });
    const debouncedValue = useDebounce()

    function updateInfosItem(key, value) {
        setInfos({ ...infos, [key]: value });
    }

    useEffect(() => {
        let okay = []; let des = []; let sat = []; let pro = []
        if (data) {
            let nomes = [...new Set(data?.dados?.map(item => item.nome))];

            for (let count = 0; count < nomes.length; count++) {
                for (let aux = 0; aux < data?.dados?.length; aux++) {
                    if (nomes[count] === data?.dados?.[aux].nome) {
                        if (data?.dados?.[aux].estado === 'Desligado') {
                            des[count] = data?.dados?.[aux].totalOcorrencias
                        }
                        if (data?.dados?.[aux].estado == 'OK') {
                            okay[count] = data?.dados?.[aux].totalOcorrencias
                        }
                        if (data?.dados?.[aux].estado === 'Saturado') {
                            sat[count] = data?.dados?.[aux].totalOcorrencias
                        }
                        if (data?.dados?.[aux].estado === 'Problemas') {
                            pro[count] = data?.dados?.[aux].totalOcorrencias
                        }
                    }
                }
            }

            setChartData({ nomes: nomes, okay: okay, sat: sat, down: pro, off: des })
        }
    }, [data])

    function search() {
        let endPoint = ''
        switch (infos?.Tipo) {
            case 'server':
                endPoint = 'getServersStatistics'
                break;
            case 'container':
                endPoint = 'getContainersStatistics'
                break;
            case 'app':
                endPoint = 'getAppsStatistics'
                break;
            default:
                endPoint = 'getAppsStatistics'
                break;
        }
        (async () => {
            await FetchData({ ...infos, page: page, rows: perPage, Nome: debouncedValue }, endPoint, 'POST', true, 'relatorio')
        })()
    }

    useEffect(() => {
        search()
    }, [infos, page, perPage, debouncedValue])

    return (
        <div className={styles.container}>
            <div>
                <Filter setToogle={setToogle} toogle={toogle} infos={infos} setInfos={setInfos} updateInfosItem={updateInfosItem} search={search} />
                {
                    infos?.Tipo ?
                        <div className={styles.datachart}>
                            {
                                toogle ?
                                    <Tabela setData={setTableData} page={page} setPage={setPage} load={load} data={data} obj='report' allData={data} infos={infos} />
                                    :
                                    <Chart data={chartdata} />
                            }
                        </div>
                        : <h5>Coloque os parâmetros de pesquisa</h5>
                }
            </div>
        </div>
    );
};

export default Relatorio;
