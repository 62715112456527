import React, { useEffect, useState } from "react";
import Form from "../../components/form/Form";
import styles from './Registo.module.css'
import { app } from '../../utils/variables/app'
import { container } from '../../utils/variables/container'
import { server } from '../../utils/variables/server'
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { Divider, Paper } from "@mui/material";
import { UpdateItems } from "../../store";
import { useRecoilState } from "recoil";

function returnObjs(type) {
    switch (type) {
        case 'app': return app;
        case 'server': return server;
        case 'container': return container;
        default: return null
    }
}

function Registo() {
    const [dados, setDados] = useRecoilState(UpdateItems)
    const [obj, setObj] = useState();

    useEffect(() => {
        /**
         * dados eh o context que recebe os parametros de no caso 
         * de estiver a se fazer update de algum objecto
         */
        if (dados.object) {
            setObj(dados.object == 'cont' ? 'container' : dados.object)
        } else {
            setObj('server')
        }
        return () => setDados({})
    }, [])

    const ops = [
        { "key": "server", "name": "Servidor", "variant": obj === 'server' && 'contained' },
        { "key": "container", "name": "Container", "variant": obj === 'container' && 'contained' },
        { "key": "app", "name": "Aplicação ", "variant": obj === 'app' && 'contained' }
    ]

    return (
        <div className={styles.container}>
            <div>
                <div className={styles.options}>
                    <ButtonGroup variant='outlined' color='primary' aria-label="contained primary button group">
                        {
                            ops?.map((ops, index) => {
                                return <Button variant={ops.variant} key={index} onClick={() => { setObj(ops.key); setDados({ update: false }); }}>{ops.name}</Button>
                            })
                        }
                    </ButtonGroup>
                </div>

                <div className={styles.form}>
                    <Paper elevation={3} className={styles.paper}>
                        <div style={{ textAlign: 'center', fontSize: '22px' }}> Registo - {obj}</div>
                        <Divider style={{ margin: '5px 0' }} />
                        <Form objects={returnObjs(obj)} resource={obj} />
                    </Paper>
                </div>
            </div>
        </div>
    );
};

export default Registo;
