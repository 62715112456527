import React, { useEffect, useState } from "react";
import styles from './Lista.module.css'
import Divider from '@mui/material/Divider';
import Button from '@material-ui/core/Button';
import Tabela from '../../components/table/Tabela'
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { GeneralFetch } from "../../api/generalFetch/generalFetch"
import { RowsPerPage } from "../../store";
import { useRecoilState } from "recoil";
import useDebounce from "../../utils/search/searchDebounce";

function Lista() {
    const [obj, setObj] = useState("app");
    const [page, setPage] = useState(1);
    const { FetchData, data, load } = GeneralFetch()
    const [perPage,] = useRecoilState(RowsPerPage)
    const debouncedValue = useDebounce()

    const ops = [
        { "key": "server", "name": "Servidor", "variant": obj === 'server' && 'contained' },
        { "key": "container", "name": "Container", "variant": obj === 'container' && 'contained' },
        { "key": "app", "name": "Aplicação", "variant": obj === 'app' && 'contained' }
    ]

    useEffect(() => {
        let endPoint = 0
        switch (obj) {
            case 'server':
                endPoint = 'getServers'
                break;
            case 'container':
                endPoint = 'getContainers'
                break;
            default:
                endPoint = 'getApps'
                break;
        }
        (async () => {
            await FetchData("", `${endPoint}?page=${page}&per_page=${perPage}&name=${debouncedValue}`, 'GET', true, obj.charAt(0).toUpperCase() + obj.slice(1) + 's')
        })()
    }, [obj, page, perPage, debouncedValue])

    return (
        <div className={styles.container}>
            <div>
                <div className={styles.options}>
                    <ButtonGroup variant='outlined' size="small" color='primary' aria-label="contained primary button group">
                        {
                            ops?.map((ops, index) => {
                                return <Button variant={ops.variant} key={index} onClick={() => setObj(ops.key)}>{ops.name}</Button>
                            })
                        }
                    </ButtonGroup>
                </div>

                <Divider light sx={{ marginTop: '1%', marginBottom: '1.5%', padding: '0 12%' }} />

                <div className={styles.tab}>
                    <Tabela page={page} setPage={setPage} load={load} data={data} obj={obj} />
                </div>
            </div>
        </div>

    );
};

export default Lista;