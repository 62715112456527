import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import styles from './Fields.module.css'
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { GeneralFetch } from '../../../api/generalFetch/generalFetch';
import { AppLocation } from '../../../store';
import { useRecoilState } from 'recoil';

const useStyles = makeStyles((theme) => ({
    formControl: {
        marginBottom: '15px',
        display: 'grid',
        gridTemplateColumns: ' 100%'
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export default function Selects({ zerar, setZerar, label, infos, setInfos, dados, options, node_id }) {
    const [location, setLocation] = useRecoilState(AppLocation)
    const classes = useStyles();
    const { FetchData, data } = GeneralFetch()
    let endPoint = 0
    let obj = 0

    const onChange = (e) => {
        if (label != 'Icone') {
            setZerar(false)
            setInfos({ ...infos, host_id: e.target.value, host: location });
        } else {
            setInfos({ node_id: node_id, icon: e.target.value, user_id: localStorage.getItem('user_id') })
        }
    }

    useEffect(() => {
        if (label != 'Icone') {
            if (dados?.servidor) {
                setLocation('Servidor')
            } else if (dados?.container) {
                setLocation('Container')
            }

            if ((location === 'Servidor' & !label) || label === 'Servidor') {
                endPoint = 'getServers'
                obj = 'Servers'
            } else {
                endPoint = 'getContainers'
                obj = 'Containers'
            }
            (async () => {
                await FetchData(null, endPoint+"?per_page=10000000", 'GET', false, obj)
            })()
        } 
    }, [label, location])

    useEffect(() => {
        if (label != 'Icone') {
            data?.map((val) => {
                if (val.nome == dados?.servidor || val.nome === dados?.container || val.id == dados?.host_id) {
                    setInfos({ ...infos, host_id: val.id })
                    return 1
                }
            })
        }
    }, [data])

    return (
        <div className={styles.container}>
            <label className={styles.label}>{label ? label : location}</label>
            <FormControl variant="outlined" size='small' className={classes.formControl}>
                <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={zerar ? 0 : label == 'Icone' ? infos?.icon : infos?.host_id || 0}
                    onChange={onChange}
                >
                    <MenuItem value="text">
                        <em>None</em>
                    </MenuItem>
                    {
                        data?.map((val, id) => {
                            if (label != 'Icone' && val.estado == 1) {
                                return <MenuItem key={id} value={val.id}>{val.nome}</MenuItem>
                            } else {
                                return <MenuItem key={id} value={val.id}>{val.nome}</MenuItem>
                            }
                        })
                    }
                </Select>
            </FormControl>
        </div>
    );
}
