import './App.css';
import Rotas from '../routes/Rotas';
import BasicModal from '../components/modal/Modal';
import Alert from '../components/snackBar/snackBar';
import { RecoilRoot } from 'recoil'

function App() {
  const { contextHolder } = Alert

  return (
    <RecoilRoot>
      <div className="App">
        {contextHolder}
        <BasicModal />
        <Alert />
        <Rotas />
      </div>
    </RecoilRoot>
  );
}

export default App;