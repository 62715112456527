import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { GeneralFetch } from '../../api/generalFetch/generalFetch';
import { SetIcon } from '../SelectIcon/setIcon';
import { Button, IconButton, List } from '@mui/material';
import { Tooltip } from "@mui/material";
import { VisibilityOff, Visibility } from "@material-ui/icons";
import { hideNodes } from '../../utils/hideNodes/hideNodes';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { OpenModal, UpdateItems } from '../../store';
import { useRecoilState } from 'recoil';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 380,
  borderRadius: '10px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const div = {
  display: 'flex',
  justifyContent: 'space-between'
}

const clusterStyle = {
  display: 'flex',
  placeContent: 'center',
}

export default function BasicModal({ setUpdateForm, ModalData, setModalData }) {
  const [disable, setDisable] = React.useState(false)
  const { FetchData, data } = GeneralFetch()
  const { returnObj } = hideNodes()
  const [obj, setObj] = React.useState("server");
  const [toogleHide, setToogleHide] = React.useState(0)
  const [open, setOpen] = useRecoilState(OpenModal)
  const [,setDados] = useRecoilState(UpdateItems)

  const btnGroupOps = [
    { "key": "server", "name": "Servidor", "variant": obj === 'server' && 'contained' },
    { "key": "container", "name": "Container", "variant": obj === 'container' && 'contained' },
    { "key": "app", "name": "Aplicação", "variant": obj === 'app' && 'contained' }
  ]

  React.useEffect(() => {
    if (localStorage.getItem('user_id')) {
      (async () => {
        let data = { 'user_id': localStorage.getItem('user_id') }
        await FetchData(data, 'getHiddenClusters', 'POST', false, 'node')
      })()
    }
  }, [toogleHide, obj, open])

  function EditClusterData() {
    let data = {
      "update": true,
      "data": {
        "id": ModalData?.id,
        "nome": ModalData?.nome,
        "host_id": ModalData?.host_id,
        "url": ModalData?.url,
        "fqdn": ModalData?.fqdn,
        "upstream": ModalData?.upstream,
        "dns": ModalData?.dns,
        "ip": ModalData?.ip,
        "servidor": getHostName(ModalData?.host_id, "server"),
        "container": getHostName(ModalData?.host_id, "container"),
        "hidden": ModalData?.hidden
      },
      "object": ModalData?.type
    }
    setDados(data)
    sessionStorage.setItem("menuItem", "Registo")
    window.location.replace(window.location.protocol + '//' + window.location.host + '/#/dashboard/registo')
  }

  const handleClose = () => {
    if (ModalData?.open) {
      setModalData(false)
    } else {
      setOpen(false)
    }
  };

  function getHostName(id, hostName) {
    if (id?.includes(hostName)) {
      return hostName
    } else {
      return null
    }
  }

  function hide(type, visible, cluster_id) {
    let obj = returnObj(type, visible, cluster_id);
    (async () => {
      let response = await FetchData(obj, 'hideClusters', 'POST', false, 'nodeIcon');
      if (response == 1) {
        setUpdateForm({ visibility: true, all: false, id: cluster_id, show: !ModalData?.hidden })
      }
    })()
  }

  function returnNode(node) {
    return (
      <ListItem >
        <ListItemText primaryTypographyProps={{ fontSize: 13, fontWeight: 'thin' }}
          primary={node.nome} />
        <Tooltip onClick={() => { hide(obj, 'false', node.id); setToogleHide(toogleHide + 1) }} title='Mostrar'>
          <Visibility />
        </Tooltip>
      </ListItem>
    )
  }

  function modalChild() {

    switch (open?.component) {
      case 'ssl_details':
        return (
          <Box sx={style}>
            {
              open.app.data_pagamento && open.app.data_validade ?
                <React.Fragment>
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                    Detalhes Certificado SSL - {open.app.label}
                  </Typography>
                  <div style={{ display: 'grid', gridTemplateColumns: 'auto auto', columnGap: '5%', fontSize: '17px' }}>
                    <div style={{ textAlign: 'right' }}>
                      <p><strong>Nome:</strong></p>
                      <p><strong>Estado:</strong></p>
                      <p><strong>Nome SSL:</strong></p>
                      <p><strong>Tipo:</strong></p>
                      <p><strong>Provedor:</strong></p>
                      <p><strong>Registo:</strong></p>
                      <p><strong>Validade:</strong></p>
                      <p><strong>Expira em:</strong></p>
                    </div>
                    <div style={{ textAlign: 'left' }}>
                      <p> {open.app.label ? open.app.label : '-'}</p>
                      <p> {open.app.estado == 0 ? 'Desligado' : open.app.estado == 1 ? 'Ligado' : '-'}</p>
                      <p> {open.app.nome ? open.app.nome : '-'}</p>
                      <p> {open.app.tipo ? open.app.tipo : '-'}</p>
                      <p> {open.app.provedor ? open.app.provedor : '-'}</p>
                      <p> {open.app.data_pagamento ? open.app.data_pagamento : '-'}</p>
                      <p> {open.app.data_validade ? open.app.data_validade : '-'}</p>
                      <p> {open.app.diasRestantes ? open.app.diasRestantes + ' dias' : '-'}</p>
                    </div>
                  </div>
                </React.Fragment>
                :
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Não foram registados dados de Certificado SSL da aplicação - {open.app.label}
                </Typography>
            }
          </Box>
        )

      default:
        return (
          <Box sx={style}>
            {ModalData?.component == 'hidden_nodes' ?
              <div>
                <Typography id="modal-modal-title" variant="h6" component="h2" style={{ textAlign: 'center' }}>
                  Clusters Ocultos
                </Typography>
                <div>
                  <div style={clusterStyle}>
                    <ButtonGroup variant='outlined' size="small" color='primary' aria-label="contained primary button group">
                      {
                        btnGroupOps?.map((ops, index) => {
                          return <Button variant={ops.variant} key={index} onClick={() => setObj(ops.key)}>{ops.name}</Button>
                        })
                      }
                    </ButtonGroup>
                  </div>

                  <Divider light sx={{ marginTop: '1%', marginBottom: '1.5%', padding: '0 12%' }} />

                  <div>
                    <List>
                      {
                        obj == 'app' ?
                          data && data?.apps?.map((node) => {
                            return (
                              returnNode(node)
                            )
                          })
                          : obj == 'server' ?
                            data && data?.servers?.map((node) => {
                              return (
                                returnNode(node)
                              )
                            })
                            :
                            data && data?.containers?.map((node) => {
                              return (
                                returnNode(node)
                              )
                            })
                      }
                    </List>
                  </div>
                </div>
              </div>
              :
              <div>
                {ModalData?.type == 'app' ?
                  <div style={div} >
                    <span style={{ fontWeight: 'bold', fontSize: '20px' }} >Aplicação</span>
                    <IconButton disabled={disable} onClick={() => { hide('app', ModalData?.hidden, ModalData.id); setDisable(true) }} >
                      <Tooltip title={ModalData?.hidden ? 'Mostrar' : 'Esconder'}>
                        {ModalData?.hidden ?
                          <Visibility />
                          :
                          <VisibilityOff />}
                      </Tooltip>
                    </IconButton>
                  </div>
                  : ModalData?.type == 'cont' ?
                    <div style={div}  >
                      <span style={{ fontWeight: 'bold', fontSize: '20px' }} >Container </span>
                      <IconButton disabled={disable} onClick={() => { hide('container', ModalData?.hidden, ModalData.id); setDisable(true) }} >
                        <Tooltip title={ModalData?.hidden ? 'Mostrar' : 'Esconder'}>
                          {ModalData?.hidden ?
                            <Visibility />
                            :
                            <VisibilityOff />
                          }
                        </Tooltip>
                      </IconButton>
                    </div>
                    :
                    <div style={div} >
                      <span style={{ fontWeight: 'bold', fontSize: '20px' }} >Servidor</span>
                      <IconButton disabled={disable} onClick={() => { hide('server', ModalData?.hidden, ModalData.id); setDisable(true) }} >
                        <Tooltip title={ModalData?.hidden ? 'Mostrar' : 'Esconder'}>
                          {ModalData?.hidden ?
                            <Visibility />
                            :
                            <VisibilityOff />}
                        </Tooltip>
                      </IconButton>
                    </div>
                }
                <hr></hr>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }} >
                  <span style={{ fontSize: '18px' }} ><span style={{ fontWeight: 'bold' }} >Nome: </span>{ModalData?.nome} </span>
                  <span style={{ fontSize: '18px' }} ><span style={{ fontWeight: 'bold' }}>Status: </span> {ModalData?.status ? ModalData?.status : '--'}</span>
                </div>

                <div style={{ marginLeft: '15px' }}>
                  <SetIcon node_id={ModalData?.id} setUpdateForm={setUpdateForm} />
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: '10px' }} >
                  <a href={ModalData?.url} target="_blank" style={{ marginTop: '10px', marginBottom: '10px', paddingRight: '10px' }} > {ModalData?.type == 'app' && 'Ver aplicacao'}</a >
                  <div>
                    {localStorage.getItem('acesso') == 'admin' &&
                      < Button
                        variant="contained"
                        size="small"
                        onClick={() => { EditClusterData() }}
                      >
                        Editar
                      </Button>
                    }
                  </div>
                </div>
              </div>
            }
          </Box >
        )
    }
  }

  return (
    <div>
      <Modal
        open={ModalData?.open || open.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {modalChild()}
      </Modal>
    </div>
  );
}