import { TableCell, TableRow } from "@mui/material";

export default function Nothing2Show() {
    return (
        <TableRow>
            <TableCell style={{ minWidth: '75px' }} >
                Sem dados para mostar!
            </TableCell>
        </TableRow>
    )
}