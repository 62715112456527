import { useState, useEffect } from 'react'
import { GeneralFetch } from '../../api/generalFetch/generalFetch'

export function OrganizeGraphData({ setError, client, load }) {
    const [graph, setgrap] = useState(5)
    const { FetchData, data } = GeneralFetch()
    let response
    let nodes = [], links = []

    useEffect(() => {
        (async () => {
            if (client == 'dash') {
                response = await FetchData(null, 'getNodes/' + localStorage.getItem('acesso') + '/' + localStorage.getItem('user_id'), 'GET', false, 'nodes')
            } else if (client == 'ssl' && load == false) {
                response = await FetchData(null, 'getCertificates', 'GET', false, 'apps')
                if (response == 0) {
                    setError(true)
                } else if (response.apps.apps.length > 0) {
                    setgrap(true)
                } else {
                    setgrap(false)
                }
            }
            if (response == 0) {
                setError(true)
            } if (response?.[0]?.servers?.length > 0 || response?.apps) {
                setgrap(true)
            } else {
                setgrap(false)
            }
        })()
    }, [load])

    if (client == 'dash') {
        data[0]?.servers?.map((n) => {
            let node = {
                type: `${n.type}`,
                id: `${n.id}`,
                label: `${n.nome}`,
                shape: `${n.icon ? n.icon : "box"}`,
                color: 'red',
                font: {
                    color: 'white',
                },
                ip: `${n.ip}`,
                dns: `${n.dns}`,
                hidden: n.hidden ? `${n.hidden}` : false,
            }
            nodes.push(node)
        })

        data[0]?.containers?.map((n) => {
            let node = {
                type: `${n.type}`,
                node_pai: `${n.server_id}`,
                id: `${n.id}`,
                label: `${n.nome}`,
                shape: `${n.icon ? n.icon : "hexagon"}`,
                color: "red",
                ip: `${n.ip}`,
                fqdn: `${n.fqdn}`,
                upstream: `${n.upstream}`,
                hidden: n.hidden ? `${n.hidden}` : false,
            }
            nodes.push(node)
        })
    }

    (data?.[0]?.apps || data?.apps)?.map((n) => {
        let node = {
            type: `${n.type}`,
            node_pai: n.container_id != '0' ? `${n.container_id}` : `${n.server_id}`,
            id: `${n.id}`,
            label: `${n.nomeApp || n.nome}`,
            shape: `${n.icon ? n.icon : "dot"}`,
            color: `${n.diasRestantes <= 0 ? 'red' : (n.diasRestantes > 0 && n.diasRestantes <= 15) ? 'yellow' : n.diasRestantes > 15 ? 'green' : 'red'}`,
            data_pagamento: `${n.data_pagamento}`,
            data_validade: `${n.data_validade}`,
            nome: `${n.nome}`,
            diasRestantes: `${n.diasRestantes}`,
            provedor: `${n.provedor}`,
            tipo: `${n.tipo}`,
            estado: `${n.estado}`,
            url: `${n.url}`,
            hidden: n.hidden ? `${n.hidden}` : false,
        }
        nodes.push(node)
    })

    nodes.map((n) => {
        if (n.node_pai) {
            let lnk = {
                to: `${n.node_pai}`,
                from: `${n.id}`,
                arrows: "from"
            }
            links.push(lnk)
        }
    })

    return {
        links: graph == 5 ? 0 : graph == true ? links : 1,
        nodes: graph == 5 ? 0 : graph == true ? nodes : 1,
        hidden: data[1]
    }
}