import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import styles from './Fields.module.css'
import { AppLocation, UpdateItems } from '../../../store';
import { useRecoilState } from 'recoil';

export default function RadioButtonsGroup({ label, options, onChange }) {
    const [location, setLocation] = useRecoilState(AppLocation)
    const [, setDados] = useRecoilState(UpdateItems)


    const handleChange = (event) => {
        setDados({ update: false })
        setLocation(event.target.value);
    };

    return (
        <div className={styles.container} style={{ marginBottom: '10px' }} >
            <label className={styles.label}>{label}</label>
            <FormControl component="fieldset" >
                <RadioGroup value={location} onChange={handleChange} onClick={onChange}>
                    <div className={styles.radio}>
                        {
                            options?.map((option) => {
                                return (
                                    <FormControlLabel value={option.key} control={<Radio color="primary" />} label={option.name} />
                                )
                            })
                        }
                    </div>
                </RadioGroup>
            </FormControl>
        </div>
    );
}
