import { useEffect } from "react";
import { GeneralFetch } from '../generalFetch/generalFetch'

export function StatusChecker({ setStatus, setOperationData }) {
    const { FetchData, data } = GeneralFetch()
    const MINUTE_MS = 71000;

    useEffect(() => {
        setStatus(data)
    }, [data])

    useEffect(() => {
        ServerChecker()
        setInterval(ServerChecker, MINUTE_MS);
    }, [])

    async function ServerChecker() {
        (async () => {
            const obj = { 'lastTime': sessionStorage.getItem('lastTimeServer') }
            let response = await FetchData(obj, 'checkServerStatus', 'POST', false, 'data')
            if (response) {
                if (response?.data?.lastTime) {
                    sessionStorage.setItem('lastTimeServer', response?.data?.lastTime)
                    setOperationData({ lastTime: response?.data?.lastTime, 'type': 'server' })
                }
                checkContainers()
            }
        })()
    }

    async function checkContainers() {
        const obj = { 'lastTime': sessionStorage.getItem('lastTime') }
        let response = await FetchData(obj, 'checkConatinerStatus', 'POST', false, 'data')
        if (response) {
            if (response?.data?.lastTime) {
                sessionStorage.setItem('lastTime', response?.data?.lastTime)
                setOperationData({ lastTime: response?.data?.lastTime, 'type': 'cont' })
            }
            CheckApp()
        }
    }

    async function CheckApp() {
        const obj = { 'lastTime': sessionStorage.getItem('lastTimeApp') }
        let response = await FetchData(obj, 'checkAppStatus', 'POST', false, 'data')
        if (response?.data?.lastTime) {
            sessionStorage.setItem('lastTimeApp', response?.data?.lastTime)
            setOperationData({ lastTime: response?.data?.lastTime, 'type': 'app' })
        }
    }

    return <></>
}