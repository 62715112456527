import TableHead from '@mui/material/TableHead';
import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import CircularProgress from '@material-ui/core/CircularProgress';
import Nothing2Show from './nothing2show';
import ReturnValue from '../../utils/tableUtils/returnRowValue';
import TabFooter from './tableFooter';
import { useRecoilState } from 'recoil';
import { RowsPerPage, SearchData, UserUpdate } from '../../store';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardArrowLeft } from '@mui/icons-material';

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
    fontFamily: 'Calibri, sans-serif !important',
  },
}));


const useStyles2 = makeStyles({
  table: {
    minWidth: 500,
    textTransform: 'none ! important'
  },
});

export default function BasicTable({ page, setPage, setData, data, obj, load, infos }) {
  const classes = useStyles2();
  const [state, setState] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useRecoilState(RowsPerPage)
  const [, setSelectedUser] = useRecoilState(UserUpdate)
  const [seacrh,] = useRecoilState(SearchData)


  function TablePaginationActions(props) {
    const classes = useStyles1();

    const handleBackButtonClick = () => {
      setPage(page - 1);
    };

    const handleNextButtonClick = () => {
      setPage(page + 1);
    };

    return (
      <div className={classes.root}>
        <IconButton onClick={handleBackButtonClick} disabled={page - 1 === 0} aria-label="previous page">
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page - 1 >= Math.ceil(data.total / rowsPerPage) - 1}
          aria-label="next page"
        >
          <KeyboardArrowRight />
        </IconButton>
      </div>
    );
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  return (
    <TableContainer component={Paper} style={{ maxHeight: "78vh", overflowY:"auto" }} >
      {load ?
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '75vh' }}>
          <CircularProgress color="primary" />
        </div>
        : <Table className={classes.table} aria-label="custom pagination table" size="small">
          <>
            <TableHead >
              <TableRow>
                {data?.headers != 0 &&
                  data?.headers?.map((h) => <TableCell align='center' key={h.key}>{h.key}</TableCell>)
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {(data.dados?.slice(0, rowsPerPage))?.map((row, id) => (
                <TableRow
                  key={id}
                  onClick={() => setSelectedUser(row)}
                >
                  {data.headers?.map((h) =>
                    <ReturnValue
                      infos={infos}
                      id={row.id}
                      row={row}
                      value={h.value}
                      obj={obj}
                      status={row.estado}
                      state={state}
                      setState={setState}
                      data={data}
                    // setData={setData}
                    />)}
                </TableRow>
              ))}

              {!data.headers && <Nothing2Show />}
            </TableBody>
            <TabFooter data={data} TablePaginationActions={TablePaginationActions} page={page} handleChangeRowsPerPage={handleChangeRowsPerPage} rowsPerPage={rowsPerPage} />
          </>
        </Table>
      }
    </TableContainer>
  );
}