export const chartData = (data) => {

  const options = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    legend: {},
    xAxis: {
      type: 'value'
    },
    yAxis: {
      type: 'category',
      data: data?.data?.nomes
    },
    series: [
      {
        name: 'Desligado',
        type: 'bar',
        stack: 'total',
        label: {
          show: true
        },
        emphasis: {
          focus: 'series'
        },
        itemStyle: {
          color: '#8A8E91'
        },
        data: data?.data?.off
      },
      {
        name: 'Up',
        type: 'bar',
        stack: 'total',
        label: {
          show: true
        },
        emphasis: {
          focus: 'series'
        },
        itemStyle: {
          color: '#61D095'
        },
        data: data?.data?.okay
      },
      {
        name: 'Saturado',
        type: 'bar',
        stack: 'total',
        label: {
          show: true
        },
        emphasis: {
          focus: 'series'
        },
        itemStyle: {
          color: 'yellow'
        },
        data: data?.data?.sat
      },
      {
        name: 'Down',
        type: 'bar',
        stack: 'total',
        label: {
          show: true
        },
        emphasis: {
          focus: 'series'
        },
        itemStyle: {
          color: '#FF6978'
        },
        data: data?.data?.down
      },
    ]
  }

  return {
    options: options
  }
};


