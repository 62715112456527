let ipRegex = /[^0-9,.]/g;
let dnsRegex = /[^a-z,.,]/g;
let nomeRegex = /[^a-z,^A-Z,^0-9]/g;

export const server = [
    {
        "key":"ip",
        "label": "IP",
        "valueType": "text",
        "regex":ipRegex,
        "replace":""
    },
    {
        "key":"dns",
        "label": "DNS",
        "valueType": "text",
        "regex":dnsRegex,
        "replace":""
    },
    {
        "key":"nome",
        "label": "Nome",
        "valueType": "text",
        "regex":nomeRegex,
        "replace":"_"
    }
]